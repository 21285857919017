/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/*
.p-image-left:before {
  content: "";
  display: block;
  background: url('https://scontent.fath5-1.fna.fbcdn.net/v/t1.0-9/31302025_10157290459718709_7915736850944229376_o.jpg?_nc_cat=109&_nc_ohc=ggACRK_sXnYAX_CRDV5&_nc_ht=scontent.fath5-1.fna&oh=4f8f115a9ad6d59b463a4a53832a7992&oe=5ECB4A15') no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px;
  margin-right: 1rem;
  float: left;
}
*/