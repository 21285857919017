/*react-toastify*/
.Toastify__toast-container {
  width: 400px;
  text-align: center;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #48bb78; /*bg-green-500*/
}
.Toastify__toast--warning {
  background: #ecc94b; /*bg-yellow-500*/
}
.Toastify__toast--error {
  background: #f56565; /*bg-red-500*/
}

/*react-bingmaps*/
#MicrosoftNav {
  margin-top: 1rem;
}

.NavBar_MapTypeButtonContainerWrapper, #LocateMeButton {
  display: none;
}

.bm_bottomLeftOverlay, .bm_bottomRightOverlay {
  display: none;
}